import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

export default class Renderer {
    private container;

    public width;
    public height;

    public freeze = true;

    public scene;
    public renderer;
    public camera;
    public orbit;

    constructor(container) {
        this.width = window.innerWidth;
        this.height = window.innerHeight;

        // Scene
        this.scene = new THREE.Scene();
        this.scene.name = 'Scene';

        // Renderer
        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
        });
        this.renderer.setClearColor(0xcccccc, 1);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.width, this.height);

        this.container = container;
        this.container.appendChild(this.renderer.domElement);

        // Camera
        this.camera = new THREE.PerspectiveCamera(60, this.width / this.height, 1, 20000);
        this.camera.name = 'Camera';
        this.camera.position.set(0, 25, -0.1);
        this.scene.add(this.camera);

        // Orbit
        this.orbit = new OrbitControls(this.camera, this.renderer.domElement);
        this.orbit.device = false;
        this.orbit.inverse = false;
        this.orbit.enableKeys = false;
        this.orbit.target.set(0, 0, 0);
        this.orbit.addEventListener('change', () => this.render())
        this.orbit.update();

        // Setup
        this.listen();
        this.render();
    }

    listen() {
        window.addEventListener("orientationchange", () => this.resize(), false);
        window.addEventListener("resize", () => this.resize(), false);
    }

    resize(width = 0, height = 0) {
        this.width = width || window.innerWidth;
        this.height = height || window.innerHeight;

        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();

        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setSize(this.width, this.height);
    }

    render() {
        this.renderer.render(this.scene, this.camera);

        if (this.freeze) {
            return;
        }

        window.requestAnimationFrame(() => this.render());
    }
}
