import React, { createRef, useEffect, useRef, useState } from 'react';

import RoomPlanner from './vr-tour/vr-tour';

export default function App() {
  let containerRef = createRef<HTMLDivElement>();
  let roomPlannerRef = useRef<RoomPlanner>();

  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    roomPlannerRef.current = new RoomPlanner(containerRef.current);
    roomPlannerRef.current.loadFinished.add(() => {
      setFinished(true);
    });
  }, []);

  return (
      <div>
        <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: started ? 'inherit' : '100%',
              height: started ? 'inherit' : '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
        >
          {started
              ? (
                  <div>
                    <button className="btn btn-primary" onClick={() => roomPlannerRef.current && roomPlannerRef.current.start()}>Start</button>
                    <button className="btn btn-warning" onClick={() => roomPlannerRef.current && roomPlannerRef.current.back()}>Back</button>
                  </div>
              )
              : (
                  <div style={{ position: 'absolute', top: '50%', width: '100%' }}>
                    <div className="progress" style={{ height: 25 }}>
                      <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: '100%' }}
                      />
                    </div>
                    {finished && (
                        <div className="text-center mt-4">
                          <button className="btn btn-primary" onClick={() => setStarted(true)}>Start</button>
                        </div>
                    )}
                  </div>
              )
          }
        </div>

        <div ref={containerRef}/>
      </div>
  );
}
