import { Signal } from 'signals';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import TWEEN from '@tweenjs/tween.js';

import Renderer from './engine/renderer';

function animate(time) {
    requestAnimationFrame(animate);
    TWEEN.update(time);
}

requestAnimationFrame(animate);

export default class RoomPlanner {
    private renderer;

    public isAnimate = false;

    public loadProgress = new Signal();
    public loadFinished = new Signal();

    constructor(container) {
        this.renderer = new Renderer(container);

        this.setup();
    }

    private setupRenderer() {
        this.renderer.orbit.enabled = false;
        this.renderer.orbit.target = this.renderer.scene.position.clone();
        this.renderer.orbit.update();
    }

    private setupScene() {
        const ambientLight = new THREE.AmbientLight(0x777777, 10);
        ambientLight.name = 'AmbientLight';
        this.renderer.scene.add(ambientLight);

        const hemisphereLight = new THREE.HemisphereLight(0xffffbb, 0x080820, 1);
        hemisphereLight.name = 'HemisphereLight';
        this.renderer.scene.add(hemisphereLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
        directionalLight.name = 'DirectionalLight';
        directionalLight.position.set(0, 1, -1).multiplyScalar(10);
        this.renderer.scene.add(directionalLight);
        const directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.6);
        directionalLight1.name = 'DirectionalLight';
        directionalLight1.position.set(1, 1, -1).multiplyScalar(10);
        this.renderer.scene.add(directionalLight1);
        const directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.6);
        directionalLight2.name = 'DirectionalLight';
        directionalLight2.position.set(-1, 1, -1).multiplyScalar(10);
        this.renderer.scene.add(directionalLight2);
    }

    private setup() {
        this.setupRenderer();
        this.setupScene();

        const progress = [0, 0];

        THREE.DefaultLoadingManager.onProgress = (item, loaded, total) => {
            progress[1] = loaded / total * 100;
            console.log(progress);
            this.loadProgress.dispatch(progress);
        };
        THREE.DefaultLoadingManager.onLoad = () => {
            progress[1] = 100;
            this.loadFinished.dispatch(progress);
            this.renderer.render();
        };

        new GLTFLoader().load(
            'assets/stall2.glb',
            (gltf) => {
                this.renderer.scene.add(gltf.scene);

                progress[0] = 100;
                this.loadFinished.dispatch(progress);
                this.renderer.render();
            },
            (xhr) => {
                progress[0] = (xhr.loaded / 134349652) * 100;

                console.log(progress);
                this.loadProgress.dispatch(progress);
            },
            (error) => {
                console.error('An error happened:', error);
            },
        );

        this.renderer.render();
    }

    start() {
        if (this.isAnimate) {
            return;
        }
        this.isAnimate = true;

        this.renderer.orbit.enabled = false;

        new TWEEN.Tween({
            x: this.renderer.camera.position.x,
            y: this.renderer.camera.position.y,
            z: this.renderer.camera.position.z,
        })
            .to({ x: 0, y: 2, z: -6 }, 3000)
            .easing(TWEEN.Easing.Cubic.In)
            .onUpdate((coords) => {
                this.renderer.camera.position.set(coords.x, coords.y, coords.z);
                this.renderer.camera.lookAt(this.renderer.scene.position);
                this.renderer.render();
            })
            .onComplete(() => {
                this.renderer.orbit.enabled = true;
                this.renderer.orbit.target.set(0, 1.67, -5);
                this.renderer.orbit.update();
                this.renderer.render();
                this.isAnimate = false;
            })
            .start();
    }

    back() {
        if (this.isAnimate) {
            return;
        }
        this.isAnimate = true;

        this.renderer.orbit.enabled = false;
        this.renderer.orbit.target.set(0, 0, 0);

        new TWEEN.Tween({
            x: this.renderer.camera.position.x,
            y: this.renderer.camera.position.y,
            z: this.renderer.camera.position.z,
        })
            .to({ x: 0, y: 25, z: -0.1 }, 3000)
            .easing(TWEEN.Easing.Cubic.In)
            .onUpdate((coords) => {
                this.renderer.camera.position.set(coords.x, coords.y, coords.z);
                this.renderer.camera.lookAt(this.renderer.scene.position);
                this.renderer.render();
            })
            .onComplete(() => {
                this.renderer.render();
                this.isAnimate = false;
            })
            .start();
    }
}
